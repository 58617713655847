import { Typography, Row, Col } from "antd";

function TermsOfService() {
  return (
    <Typography component="div" style={{ height: "100vh", overflow: "auto" }}>
      <Row type="flex" justify="center" style={{ margin: "20px" }}>
        <Col span={20}>
          <Typography.Title level={2}>TERMS OF SERVICE</Typography.Title>

          <Typography.Paragraph>
            Last updated: November 6, 2024
          </Typography.Paragraph>

          <Typography.Paragraph>
            Welcome to Meowow! Please read these Terms of Service carefully. By
            using Meowow, you agree to these terms.
          </Typography.Paragraph>

          <Typography.Title level={3} id="1">
            1. Use of Service
          </Typography.Title>

          <Typography.Paragraph>
            - You must be at least 13 years old to use Meowow.
            <br />
            - You are responsible for maintaining the security of your account
            and password. Sharing your account with others is not allowed.
            <br />- You agree to use the app lawfully and not engage in any
            activity that could harm the service, other users, or the platform.
          </Typography.Paragraph>

          <Typography.Title level={3} id="2">
            2. Ownership of Content
          </Typography.Title>

          <Typography.Paragraph>
            Meowow provides various resources within the app, including
            translation tools, music, images, and stickers, for personal use
            only:
            <br />- <b>Music</b>: All music used in Meowow is fully licensed,
            and Meowow holds the rights to use it within the app.
            <br />- <b>Canva Images and Design Elements</b>: Some images and
            design elements are created using Canva and are used under Canva’s
            licensing terms. Meowow does not own the copyrights to these Canva
            materials.
            <br />
            <b>Limited License</b>: By using Meowow, you receive a
            non-exclusive, non-transferable license to use the app’s content and
            features. You may not modify, sell, or distribute any content
            outside the app.
          </Typography.Paragraph>

          <Typography.Title level={3} id="3">
            3. Payments & Subscriptions
          </Typography.Title>

          <Typography.Paragraph>
            - Some features require in-app purchases or subscriptions to access.
            <br />
            - Payments are processed by third-party providers (e.g., Apple App
            Store) in accordance with their terms.
            <br />- <b>Automatic Renewal</b>: Subscriptions renew automatically
            unless canceled at least 24 hours before the next billing period.
            <br />- <b>Refunds</b>: Refunds will follow the policies set by the
            Apple App Store.
          </Typography.Paragraph>

          <Typography.Title level={3} id="4">
            4. Third-Party Content
          </Typography.Title>

          <Typography.Paragraph>
            - Meowow uses images and design elements created with Canva, under
            Canva's licensing terms. All copyrights remain with their original
            creators or Canva.
            <br />- Users may not redistribute or commercialize Canva-based
            content outside the app.
          </Typography.Paragraph>

          <Typography.Title level={3} id="5">
            5. Disclaimer
          </Typography.Title>

          <Typography.Paragraph>
            - Meowow’s services, including translations and music, are for
            informational and entertainment purposes only and do not constitute
            medical advice. For health-related concerns about your pet, please
            consult a veterinarian.
            <br />- <b>Service as-is</b>: Meowow is provided “as-is” without
            warranties of uninterrupted or error-free service.
            <br />- <b>External Links</b>: Meowow may include links to external
            content. We are not responsible for the accuracy, availability, or
            practices of third-party sites.
          </Typography.Paragraph>

          <Typography.Title level={3} id="6">
            6. Limitation of Liability
          </Typography.Title>

          <Typography.Paragraph>
            - To the fullest extent permitted by law, Meowow is not liable for
            any direct or indirect damages resulting from your use of the app.
            <br />- We are not responsible for service disruptions caused by
            uncontrollable events such as natural disasters or technical
            failures.
          </Typography.Paragraph>

          <Typography.Title level={3} id="7">
            7. User Responsibilities
          </Typography.Title>

          <Typography.Paragraph>
            - You are responsible for all activities carried out under your
            account.
            <br />- Meowow reserves the right to suspend or terminate your
            account for violations of these terms or any harmful behavior.
          </Typography.Paragraph>

          <Typography.Title level={3} id="8">
            8. Privacy Policy
          </Typography.Title>

          <Typography.Paragraph>
            Your use of Meowow is governed by our Privacy Policy, which explains
            how we collect, use, and protect your personal information.
          </Typography.Paragraph>

          <Typography.Title level={3} id="9">
            9. Modifications & Termination of Service
          </Typography.Title>

          <Typography.Paragraph>
            - Meowow reserves the right to modify the app or these terms at any
            time. We will notify you in advance of significant changes.
            <br />- You may cancel your subscription at any time. No refunds
            will be issued for canceled subscriptions.
          </Typography.Paragraph>

          <Typography.Title level={3} id="10">
            10. Governing Law
          </Typography.Title>

          <Typography.Paragraph>
            These terms are governed by the laws of the United States, with any
            disputes to be resolved in the courts of New York.
          </Typography.Paragraph>

          <Typography.Title level={3} id="11">
            11. Contact Us
          </Typography.Title>

          <Typography.Paragraph>
            If you have any questions or need assistance, feel free to contact
            us:
            <br />- Email: support@gathertomorrow.com
          </Typography.Paragraph>
        </Col>
      </Row>
    </Typography>
  );
}

export default TermsOfService;
