import { Typography, Row, Col } from "antd";

function Privacy() {
  return (
    <Typography component="div" style={{ height: "100vh", overflow: "auto" }}>
      <Row type="flex" justify="center" style={{ margin: "20px" }}>
        <Col span={20}>
          <Typography.Title level={2}>MEOWOW PRIVACY POLICY</Typography.Title>

          <Typography.Paragraph>
            Last updated: November 6, 2024
          </Typography.Paragraph>

          <Typography.Paragraph>
            Thank you for using Meowow! We take your privacy seriously. This
            policy explains how we collect, use, store, and protect your
            personal information.
          </Typography.Paragraph>

          <Typography.Title level={3} id="1">
            1. Information We Collect
          </Typography.Title>

          <Typography.Paragraph>
            - <b>Account Information</b>: Such as your email address and
            username, for creating and managing your account.
            <br />- <b>Cat Translation & Journal Data</b>: Includes translation
            history, voice, and journal entries for you to save and review.
            <br />- <b>Device Information</b>: Such as your IP address and
            device model, for troubleshooting and improving the service.
            <br />- <b>Permissions</b>: We may request access to your camera or
            photo library when you upload photos to your journal.
          </Typography.Paragraph>

          <Typography.Title level={3} id="2">
            2. How We Use Your Information
          </Typography.Title>

          <Typography.Paragraph>
            - <b>Enhancing User Experience</b>: To improve our translation
            accuracy and music playback features through behavior analysis.
            <br />- <b>Saving Records</b>: To store your journal entries and
            translation history for future review.
            <br />- <b>Managing Payments & Subscriptions</b>: For processing
            subscriptions and in-app purchases.
            <br />- <b>Customer Support</b>: To answer questions and resolve
            technical issues.
          </Typography.Paragraph>

          <Typography.Title level={3} id="3">
            3. How We Protect Your Data
          </Typography.Title>

          <Typography.Paragraph>
            - <b>Data Encryption</b>: We use SSL encryption to protect data
            during transmission.
            <br />- <b>Access Control</b>: Only authorized personnel can access
            your personal information.
            <br />- <b>Data Retention & Deletion</b>: We will delete all your
            data if you request to delete your account.
          </Typography.Paragraph>

          <Typography.Title level={3} id="4">
            4. Your Choices and Rights
          </Typography.Title>

          <Typography.Paragraph>
            - <b>Review and Update Information</b>: You can update your personal
            information at any time.
            <br />- <b>Revoke Permissions</b>: You can change permissions for
            camera or photo access in your device settings.
            <br />- <b>Delete Data</b>: You can request to delete your account
            and all related data.
          </Typography.Paragraph>

          <Typography.Title level={3} id="5">
            5. Sharing Your Information
          </Typography.Title>

          <Typography.Paragraph>
            We do not sell your data. We only share data in the following
            situations:
            <br />- <b>Payment Providers</b>: To process payments and manage
            subscriptions.
            <br />- <b>Legal Compliance</b>: When required by law or government
            authorities.
          </Typography.Paragraph>

          <Typography.Title level={3} id="6">
            6. Children’s Privacy
          </Typography.Title>

          <Typography.Paragraph>
            Meowow is not intended for children under the age of 13, and we do
            not knowingly collect personal data from minors.
          </Typography.Paragraph>

          <Typography.Title level={3} id="7">
            7. Policy Changes
          </Typography.Title>

          <Typography.Paragraph>
            If we update this privacy policy, we will notify you via the app or
            email and ask for your renewed consent.
          </Typography.Paragraph>

          <Typography.Title level={3} id="8">
            8. Contact Us
          </Typography.Title>

          <Typography.Paragraph>
            If you have any questions or need to request data deletion, please
            contact us:
            <br />- Email: support@gathertomorrow.com
          </Typography.Paragraph>

          <Typography.Title level={3} id="9">
            9. Compliance
          </Typography.Title>

          <Typography.Paragraph>
            We comply with <b>CCPA</b> (California Consumer Privacy Act) and{" "}
            <b>GDPR</b> (General Data Protection Regulation) to ensure the
            protection of your data.
          </Typography.Paragraph>
        </Col>
      </Row>
    </Typography>
  );
}

export default Privacy;
